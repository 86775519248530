<template>
  <ul :class="classObject">
    <li class="social__item" v-if="content.footer_social_vk">
      <a class="social__link" :href="content.footer_social_vk" target="_blank">
        <svg
          class="social__icon social__icon-vk"
          width="16"
          height="11"
          fill="none"
          viewBox="0 0 16 11"
        >
          <path
            d="M7.899 3.909 5.008 11h-1.12L.997 3.909h1.015a.385.385 0 0 1 .385.259l1.799 4.564c.056.173.105.34.147.504.042.163.082.327.119.49.037-.163.077-.327.119-.49a6.12 6.12 0 0 1 .154-.504l1.82-4.564a.385.385 0 0 1 .14-.182.363.363 0 0 1 .23-.077H7.9Zm2.396-3.22v6.069h.322a.65.65 0 0 0 .23-.035.6.6 0 0 0 .204-.161l2.24-2.401a.804.804 0 0 1 .203-.182.548.548 0 0 1 .294-.07h1.127l-2.611 2.779a1.577 1.577 0 0 1-.406.371c.084.056.158.121.224.196.07.07.135.152.196.245L15.09 11h-1.113a.732.732 0 0 1-.28-.049.492.492 0 0 1-.196-.196L11.17 7.85a.514.514 0 0 0-.21-.189.725.725 0 0 0-.308-.049h-.357V11H9.042V.689h1.253Z"
          />
        </svg>
      </a>
    </li>
    <li class="social__item" v-if="content.footer_social_in">
      <a class="social__link" :href="content.footer_social_in" target="_blank">
        <svg
          class="social__icon social__icon-in"
          width="10"
          height="11"
          fill="none"
          viewBox="0 0 10 11"
        >
          <path
            d="M1.718 3.909V11H.472V3.909h1.246Zm.266-2.226a.785.785 0 0 1-.077.343.95.95 0 0 1-.196.28.878.878 0 0 1-.287.189.85.85 0 0 1-.343.07.85.85 0 0 1-.616-.259 1.02 1.02 0 0 1-.189-.28.85.85 0 0 1-.07-.343.85.85 0 0 1 .07-.343.878.878 0 0 1 .189-.287c.08-.084.17-.15.273-.196a.85.85 0 0 1 .343-.07.85.85 0 0 1 .343.07.827.827 0 0 1 .287.196c.084.08.15.175.196.287a.785.785 0 0 1 .077.343Zm2.957 3.255c.154-.173.318-.329.49-.469.173-.14.355-.259.546-.357A2.85 2.85 0 0 1 7.3 3.797c.388 0 .728.065 1.022.196a1.9 1.9 0 0 1 .742.546c.201.233.353.516.455.847.103.331.154.698.154 1.099V11H8.42V6.485c0-.537-.123-.952-.37-1.246-.243-.299-.614-.448-1.114-.448-.368 0-.714.089-1.036.266a3.425 3.425 0 0 0-.882.721V11H3.765V3.909h.75c.177 0 .286.086.328.259l.098.77Z"
          />
        </svg>
      </a>
    </li>
    <li class="social__item" v-if="content.footer_social_fb">
      <a class="social__link" :href="content.footer_social_fb" target="_blank">
        <svg
          class="social__icon social__icon-fb"
          width="13"
          height="12"
          fill="none"
          viewBox="0 0 13 12"
        >
          <path
            d="M2.05 11V4.973l-.783-.091a.694.694 0 0 1-.245-.105.269.269 0 0 1-.091-.217v-.511h1.12v-.581c0-.42.06-.81.182-1.169.12-.36.315-.667.58-.924a2.693 2.693 0 0 1 1.03-.609c.424-.15.935-.224 1.533-.224.2 0 .394.01.58.028.187.014.374.03.56.049.192.014.39.03.596.049.205.014.431.021.679.021v4.242c.298-.345.634-.62 1.008-.826a2.684 2.684 0 0 1 1.302-.308c.415 0 .788.08 1.12.238.336.159.62.39.854.693.233.299.41.663.532 1.092.126.43.189.912.189 1.449 0 .574-.07 1.097-.21 1.568a3.56 3.56 0 0 1-.61 1.211c-.26.331-.58.59-.958.777a2.9 2.9 0 0 1-1.274.273c-.467 0-.861-.086-1.183-.259a2.632 2.632 0 0 1-.833-.749l-.063.644c-.038.177-.145.266-.322.266h-.805V1.564a4.954 4.954 0 0 0-.616-.133 3.598 3.598 0 0 0-.616-.056c-.355 0-.66.054-.917.161A1.68 1.68 0 0 0 3.38 2.628c-.08.257-.12.537-.12.84v.581h2.045v.903H3.304V11H2.05Zm5.74-1.708c.23.308.479.525.75.651.275.126.576.189.903.189.667 0 1.178-.236 1.533-.707.354-.471.532-1.174.532-2.107 0-.868-.16-1.505-.476-1.911-.313-.41-.761-.616-1.344-.616-.406 0-.761.093-1.064.28-.3.187-.577.45-.833.791v3.43Z"
          />
        </svg>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["classSocial", "content"],
  data() {
    return {
      classObject: {
        [this.classSocial]: this.classSocial,
        social: true,
      },
    };
  },
};
</script>

<style>
.social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.social__item {
  margin-right: 22px;
}

.social__item:last-child {
  margin-right: 0;
}

.social__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;

  background-color: var(--backgraund-background-bg-sm); /* Переменная CSS*/
  transition: background-color 0.3s ease-in;
}

.social__link:hover {
  background-color: var(
    --backgraund-background-bg-sm-hover
  ); /* Переменная CSS*/
}

.social__icon {
  display: block;
}

.social__icon path {
  fill: #ffffff;
  transition: fill 0.3s ease-in;
}

@media (max-width: 1599.98px) {
  .social__item {
    margin-right: 20px;
  }

  .social__link {
    width: 25px;
    height: 25px;
  }

  .social__icon-vk {
    width: 14px;
    height: 11px;
  }

  .social__icon-in {
    width: 9px;
    height: 10px;
  }

  .social__icon-fb {
    width: 12px;
    height: 11px;
  }
}

@media (max-width: 1199.98px) {
  .social__item {
    margin-right: 15px;
  }

  .social__link {
    width: 20px;
    height: 20px;
  }

  .social__icon-vk {
    width: 12px;
    height: 9px;
  }

  .social__icon-in {
    width: 7px;
    height: 8px;
  }

  .social__icon-fb {
    width: 10px;
    height: 9px;
  }
}
</style>
